import React, {Dispatch, SetStateAction} from "react";

const getPowerUpImgUrl = (powerUpID : number) =>{
    const path =  "/assets/pngs/icons/powerUps/";
    const file = ".png"
    switch (powerUpID){
        case 1:
            return path + "hyperlink-lock" + file
        case 2:
            return path + "booster" + file
        default:
            return path + "not_found" + file
    }
}

interface Props {
    powerUpID : number
    setImgLoaded?: Dispatch<SetStateAction<boolean>>
    pixelSize?: number
}
export const PowerUpImg = (props : Props) => {

    const imgLoaded = ()=>{
        if(props.setImgLoaded){
            props.setImgLoaded(true)
        }
    }

    return (<img onLoad={()=>imgLoaded()} src={getPowerUpImgUrl(props.powerUpID)} alt="powerUp" style={{width: `${props.pixelSize + "px"?? ""}`}}/>)
}