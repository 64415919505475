import React, {useEffect} from "react"
import {RequestContext} from "./RequestContext"
import {DbClient} from "../Views/LeaderBoardView/LeaderBoardView";
import {wikiSummary} from "wikipedia";
import {PowerUp} from "../Websocket/WebsocketContext";


type ProtocolType = "http" | "https"

export const RequestProvider = ({children}: any) => {

    const protocol: ProtocolType = process.env.REACT_APP_ENV === "development" ? "http" : "https"
    const url = process.env.REACT_APP_ENV === "development" ? `${protocol}://${process.env.REACT_APP_WEBSOCKET_ADDRESS}:${process.env.REACT_APP_API_PORT}/` : `${protocol}://${process.env.REACT_APP_WEBSOCKET_ADDRESS}/ws/`

    const powerUpInfo : PowerUp[] = []

    useEffect(() => {
        getPowerUpInfos().then(pui=>{
            pui.forEach(pu=>{
                powerUpInfo.push(pu)
            })
        })

    }, []);
    const buildRequestURL = (path: string): string => {
        const sanitizedPath = path.replace(/^\/+/, "")
        return url + sanitizedPath
    }
    const fetchURL = async (path : string) => {
        return await fetch(buildRequestURL(path))
    }

    const getByPageID = async (pageId: number) : Promise<wikiSummary | undefined> => {
        const res = await fetchURL(`searchByPageID?q=${pageId}`)
        return await res.json()
    }
    const getLeaderboard = async () : Promise<DbClient[]>=> {
        const res = await fetchURL("leaderboard")
        return await res.json()
    }
    const getPowerUpInfos = async () : Promise<PowerUp[]>=> {
        const res = await fetchURL("powerUpInfos")
        return await res.json()
    }
    const getRandomSummary = async ()=>{
        const res = await fetchURL("randomSummary")
        return await res.json()
    }
    const getSearchTerm = async (inputSearch : string)=>{
        const res = await fetchURL(`suggest?q=${inputSearch}`)
        return await res.json()
    }


    const getAllPowerUps = () : PowerUp[]=>{
        return powerUpInfo
    }
    const getPowerUpInfo = (powerUpID: number) : PowerUp=>{
        return powerUpInfo.find((pu) => pu.id === powerUpID) ?? powerUpInfo[0];
    }
    return (
        <RequestContext.Provider
            value={{
                getLeaderboard,
                getByPageID,
                getSearchTerm,
                getRandomSummary,
                getPowerUpInfo,
                getAllPowerUps
            }}>
            {children}
        </RequestContext.Provider>
    )
}