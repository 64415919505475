import React from "react";
import {DbClient} from "../Views/LeaderBoardView/LeaderBoardView";
import {wikiSummary} from "wikipedia";
import {wikiSearchResult} from "../Views/CreateLobbyView/InputField/CreateLobbyInput";
import {PowerUp} from "../Websocket/WebsocketContext";

export interface RequestContextType {
    getLeaderboard: () => Promise<DbClient[]>
    getRandomSummary: () => Promise<any>
    getSearchTerm: (inputSearch: string) => Promise<wikiSearchResult | undefined>
    getByPageID: (pageId: number) => Promise<wikiSummary | undefined>
    getPowerUpInfo: (powerUpID: number) => PowerUp
    getAllPowerUps: () => PowerUp[]
}
export const RequestContext = React.createContext<RequestContextType | undefined>(undefined)
