import {Link} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {RequestContext} from "../../Request/RequestContext";

export interface DbClient {
    clientID: string,
    name: string | null,
    played: number,
    wins: number,
    lastWin: string | null,
    created_at: string
}

export const LeaderBoardView = () => {

    const request = useContext(RequestContext)
    const [loading, setLoading] = useState(false)
    const [leaderboard, setLeaderboard] = useState<DbClient[]>([])

    useEffect(() => {
        setLoading(true)
        fetchLeaderBoard().then()
    }, []);

    if (!request)
        return <></>

    const {getLeaderboard} = request

    const fetchLeaderBoard = async () => {

        const leaderboard = await getLeaderboard()
        setLeaderboard(leaderboard)
        setLoading(false)
    }



    return (
        <>
            <div className="row mt-2">
                <div className="col-2"><Link to="/" className="btn btn-warning btn-sm">Zurück</Link></div>
            </div>
            <div className="row">
                {loading ?
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div> :

                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Siege</th>
                            <th scope="col">Gespielt</th>
                            <th scope="col">W/L</th>
                        </tr>
                        </thead>
                        <tbody>
                        {leaderboard.map((item, key) => {
                            return <tr>
                                <th scope="row">{key +1}</th>
                                <td>{item.name}</td>
                                <td>{item.wins}</td>
                                <td>{item.played}</td>
                                <td>{Number((item.wins / item.played).toFixed(2))}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                }
            </div>
        </>
    )
}