import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {PowerUp} from "../../../Websocket/WebsocketContext";
import './PowerUpInfoView.css'
import {HowlServiceContext, SoundKey} from "../../../HowlerService/HowlServiceContext";
import {PowerUpImg} from "../LobbyView/PowerUp/PowerUp";

interface Props {
    setPowerUpInfo: Dispatch<SetStateAction<PowerUp | null>>
    powerUpInfo: PowerUp
}

export const PowerUpInfoView = ({setPowerUpInfo, powerUpInfo}: Props) => {

    const howlServiceContext = useContext(HowlServiceContext)!
    const {playSound} = howlServiceContext
    const [powerUpImgLoaded, setPowerUpImgLoaded] = useState(false)
    const decline = ()=>{
        playSound(SoundKey.click, .2)
        setPowerUpInfo(null)
    }

    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            event.preventDefault()
            decline()
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress)
        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, []);

    return (
        <>
            <div className={`modal position-absolute ${(powerUpImgLoaded)? '': 'd-none'}`} style={{display: 'block'}}>
                <div className="modal-dialog pixer">
                    <div className="modal-custom-content_powerup box-shd-cyan">
                        <div className="modal-body py-0">
                            <div className="row">
                                <div className={`d-flex justify-content-end`}>
                                    <button onClick={() => decline()} type="button" className="modal-btn-close pixer"
                                            data-bs-dismiss="modal" aria-label="Close">X
                                    </button>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center pb-3">
                                <div>
                                    <div className={``}><PowerUpImg powerUpID={powerUpInfo.id} setImgLoaded={setPowerUpImgLoaded} pixelSize={80}/></div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className={`d-flex justify-content-center mb-3 text-uppercase`}><h3>{powerUpInfo.name}</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className={`d-flex text-yellow text-uppercase`}>Beschreibung:
                                </div>
                            </div>
                            <div className="row">
                                <div className={`d-flex mb-4 text-uppercase`}>{powerUpInfo.description}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
