import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {PowerUp} from "../../../Websocket/WebsocketContext";
import './PowerUpPickUpView.css'
import {HowlServiceContext, SoundKey} from "../../../HowlerService/HowlServiceContext";
import {PowerUpImg} from "../LobbyView/PowerUp/PowerUp";
import {RequestContext} from "../../../Request/RequestContext";

interface Props {
    setPowerUpPickUp: Dispatch<SetStateAction<boolean>>
}

export const PowerUpPickUpView = ({setPowerUpPickUp}: Props) => {

    const howlServiceContext = useContext(HowlServiceContext)!
    const request = useContext(RequestContext)!
    const {playSound} = howlServiceContext
    const {getAllPowerUps} = request
    const [powerUpImgLoaded, setPowerUpImgLoaded] = useState(false)
    const decline = ()=>{
        playSound(SoundKey.click, .2)
        setPowerUpPickUp(false)
    }

    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            event.preventDefault()
            decline()
        }
    }

    useEffect(() => {
        console.log("ok")
        window.addEventListener('keydown', handleKeyPress)
        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, []);

    return (
        <>
            <div className={`modal position-absolute ${(powerUpImgLoaded)? '': 'd-none'}`} style={{display: 'block'}}>
                <div className="modal-dialog pixer">
                    <div className="modal-custom-content_powerup box-shd-cyan">
                        <div className="modal-body py-0">
                            <div className="row">
                                <div className={`d-flex justify-content-end`}>
                                    <button onClick={() => decline()} type="button" className="modal-btn-close pixer"
                                            data-bs-dismiss="modal" aria-label="Close">X
                                    </button>
                                </div>
                            </div>
                            <div className="d-flex pb-3" >
                                <div>
                                    {getAllPowerUps().map((powerUp)=> {
                                        return (
                                            <div onClick={()=>{}} className={`mb-3 row pointer`}>
                                                <div className="col-4">
                                                    <PowerUpImg powerUpID={powerUp.id}
                                                                setImgLoaded={setPowerUpImgLoaded}
                                                                pixelSize={80}/>
                                                </div>
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-12 text-uppercase text-yellow">{powerUp.name}</div>
                                                        <div className="col-12">{powerUp.description}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
