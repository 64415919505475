import React, {useContext, useEffect, useRef, useState} from "react"
import {WebSocketContext} from "../../Websocket/WebsocketContext"
import './GameView.css'
import {GameFinishedView} from "./GameFinishedView/GameFinishedView"
import moment from "moment/moment"
import {PixiView} from "./PixiView/PixiView"
import {HowlServiceContext} from "../../HowlerService/HowlServiceContext"
import {LeaveLobbyView} from "../LobbyView/LeaveLobbyView/LeaveLobbyView";
import {AussteigenButton} from "../../Components/Buttons/AussteigenButton/AussteigenButton";
import {ListViewButton} from "../../Components/Buttons/GameViewSwitchButton/ListViewButton";
import {BlockViewButton} from "../../Components/Buttons/GameViewSwitchButton/BlockViewButton";

export const GameView = () => {

    const canvasRef = useRef<HTMLDivElement>(null)
    const context = useContext(WebSocketContext)!
    const howl = useContext(HowlServiceContext)!

    const {playSound} = howl
    const {
        sendMessage,
        setErrorMessage,
        htmlGameView,
        relatedNexus,
        nexusGameView,
        roundFinished
    } = context

    const [timeRemaining, setTimeRemaining] = useState(-1)
    const [html, setHtml] = useState("")
    const [leaveLobbyView, setLeaveLobbyView] = useState(false)
    const [pixiBlockView, setPixiBlockView] = useState(true)

    useEffect(() => {
        if (nexusGameView && nexusGameView.expires_at) {
            const interval = setInterval(() => {
                const targetDate = moment(nexusGameView.expires_at, 'YYYY-MM-DD HH:mm:ss')
                const currentTime = moment()
                const timeToStart = Math.floor(targetDate.diff(currentTime, 'seconds'))
                console.log(timeToStart)
                setTimeRemaining(timeToStart)
                if (timeToStart < 0) {
                    clearInterval(interval)
                }
            }, 1000)
        }
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === 'f') {
                event.preventDefault()
                setErrorMessage("Wer will denn wohl schummeln??")
            }
        }

        window.addEventListener('keydown', handleKeyPress)
        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [])
    useEffect(() => {
        if (htmlGameView) {
            parseHtml()
        }
    }, [htmlGameView])

    const activatePowerUp = (target? : string)=>{
        console.log(nexusGameView)
        sendMessage('activatePowerUp', {nexusID: relatedNexus, powerUpID: 1})
    }
    const handleLinkClick = (event: any) => {
        const link = event.target.getAttribute('href')
        if (!link)
            return
        event.preventDefault()
        if (!link.startsWith("/wiki/"))
            return


        const isLinkInHtml = html.includes(link)
        console.log(isLinkInHtml)

        sendMessage('requestNewWikiPage', {link, nexusID: relatedNexus})
        /* if(isLinkInHtml){
             sendMessage('requestNewWikiPage', {link, nexusID: relatedNexus})
         }else{
             setErrorMessage("Der gesuchte Link befindet sich nicht in diesem Wikipedia-Artikel.")
         }*/
    }
    const getOlderLink = (title: string) => {
        sendMessage('requestNewWikiPage', {link: `/wiki/${title}`, nexusID: relatedNexus})
    }
    const parseHtml = () => {
        try {
            const parser = new DOMParser()
            const parsedHtml = parser.parseFromString(htmlGameView, 'text/html')
            const anchorEl = parsedHtml.querySelectorAll('a')
            anchorEl.forEach((anchor) => {
                const currentHref = anchor.getAttribute('href')!
                if (!currentHref)
                    return

                if (currentHref.startsWith('/w/index.php') || currentHref.startsWith('https:') || currentHref.startsWith('/wiki/Spezial:ISBN') || currentHref.endsWith('.svg') || currentHref.endsWith('.png') || currentHref.endsWith('.jpg') || currentHref.endsWith('.gif') || (!currentHref.startsWith('/wiki/') && !currentHref.startsWith('#'))) {
                    const span = document.createElement('span')
                    span.innerHTML = anchor.innerHTML
                    anchor.parentNode?.replaceChild(span, anchor)
                } else {
                    const decodedHref = decodeURIComponent(currentHref)
                    anchor.setAttribute('href', decodedHref)
                }

            })

            setHtml(parsedHtml.body.innerHTML)
        } catch (e: any) {
            console.log("Error in parseHtml", e)
            setHtml(e)
        }
    }
    return (<>
        {leaveLobbyView ? <LeaveLobbyView setLeaveLobbyView={setLeaveLobbyView}/> : null}
        {roundFinished ? <GameFinishedView/> : null}
        <div className={`vh-100 overflow-auto`}>
         {/*   <div className="col-2 position-absolute"><button
                onClick={() => getOlderLink(nexusGameView?.endTopic.titles.canonical!)}
                className="btn btn-success btn-sm">Instant Win</button></div>*/}
             <div className="col-2 position-absolute"><button
                onClick={() => activatePowerUp()}
                className="btn btn-success btn-sm">Instant Win</button></div>

            <div className="row w-100 p-3">
                <div className="col-12 c_l_logo col-md-4">
                    <div className="row">
                        <div className="col-4 d-flex align-items-center"><img src="../../assets/pngs/logo/logo.png"
                                                                              alt="logo" style={{height: '25px'}}/>
                        </div>
                        <div className="col-4 d-flex align-items-center justify-content-center">
                            <div className="row">
                                <div className="col-6"><ListViewButton onClick={setPixiBlockView}
                                                                       active={!pixiBlockView}/></div>
                                <div className="col-6"><BlockViewButton onClick={setPixiBlockView}
                                                                        active={pixiBlockView}/></div>
                            </div>
                        </div>
                        <div className="col-4 d-flex align-items-center text-secondary">
                            {timeRemaining > 0 ?
                                <img className={`h-50 mx-2`} src={`/assets/pngs/icons/TIMER_yellow.png`} alt=""/> :
                                <img className={`h-50`} src={`/assets/pngs/icons/NO_TIMER_yellow.png`} alt=""/>}
                            {timeRemaining > 0 ? timeRemaining + ' sek' : null}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 border-yellow h-100">
                    <div className="row inter-400">
                        <div className="col-5 d-flex justify-content-center p-2">{nexusGameView?.startTopic.title}</div>
                        <div className="col-2 arrow-yellow-right"></div>
                        <div className="col-5 d-flex justify-content-center p-2">{nexusGameView?.endTopic.title}</div>
                    </div>
                </div>
                <div className="col-12 col-md-2 p-2 d-flex justify-content-end">
                    <div onClick={() => setLeaveLobbyView(true)} className={``}><AussteigenButton/></div>

                </div>
            </div>
            <div className="row w-100 h-88 p-3">
                <div className={`col-6 ${!pixiBlockView ? 'd-flex align-items-end' : null}`}>
                    <div ref={canvasRef} className="row h-88">
                        <PixiView pixiBlockView={pixiBlockView} playSound={playSound} canvasRef={canvasRef} nexusGameViewClients={nexusGameView?.connectedClients}/>
                    </div>
                </div>
                <div className="col-6 bg-white text-black overflow-auto h-85 border-bottom-blue">
                    <div onClick={(event) => handleLinkClick(event)} dangerouslySetInnerHTML={{__html: html}}/>
                </div>
            </div>
        </div>
    </>)
}